<template>
  <div>
    <base-template
        title="SupplierReceivable"
        itemView="list"
        :text-button-add="$t('addPayment')"
        :is-button-add-disabled="!selectedItems.length"
        :columns="columns"
        :filters="filters"
        :items="incomingInvoices2"
        :context="incomingInvoicesContext"
        :isActionColumnEnabled="true"
        :openViewWindow="openViewWindow"
        selectable="multi"

        @fetch="fetchIncomingInvoices2"
        @rowSelected="rowSelected"
        @addItem="addPayment(selectedItems)"
    >
      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <incoming-invoice-card
            :incoming-invoice="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-payable="true"

            @click="showDetails(item)"
            @view="showDetails(item)"
            @pay="addPayment([item])"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="showDetails(item)"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Payment-->
          <button-payment
              @click.native.stop="addPayment([item])"
              v-b-tooltip.hover.left="capitalize($t('addPayment'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>


      <!--  =========================== -->
      <!--  ===     Select view     === -->
      <!--  =========================== -->
      <template #leftViewWindow="{item}">
        <incoming-invoice-card
            :incoming-invoice="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==selectedItems[0].id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #windowView
                v-if="selectedItems.length">
        <div class="d-flex justify-content-between mb-1">
          <h4>{{ selectedItems[0].billNumber }} - {{ selectedItems[0].supplierCompany?selectedItems[0].supplierCompany._display:'' }}</h4>
          <div>

            <!--          Download-->
            <button-download
                v-b-tooltip.hover.left="$t('Download')"
                @click.native.stop="downloadIncomingInvoice(selectedItems[0])"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Close-->
            <button-close
                @click.native.stop="showDetails(null)"
                v-b-tooltip.hover.left="$t('Close')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />


<!--            <icon icon="download"-->
<!--                  class="cursor-pointer"-->
<!--                  @click.native="showDetails(null)"/>-->
<!--            <icon icon="times"-->
<!--                  class="cursor-pointer"-->
<!--                  @click.native="showDetails(null)"/>-->
          </div>
        </div>

        <b-alert
            show
            :variant="incomingInvoicePaymentBannerColor(selectedItems[0])"
        >
          <div class="alert-body d-flex justify-content-between">
            <icon icon="cash-register"
                  class="top-0 my-auto"/>
            <span class="ml-50 w-100 my-auto">
              <span v-if="incomingInvoicePaymentBannerColor(selectedItems[0]) == 'danger'">
                {{ $t('The payment of this supplier invoice is late') }}
              </span>
              <span v-else-if="incomingInvoicePaymentBannerColor(selectedItems[0]) == 'warning'">
                {{ $t('A partial payment has been recorded for this supplier invoice') }}
              </span>
              <span v-else>
                {{ $t('This supplier invoice is in Open status') }}
              </span>
            </span>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                class="text-nowrap"
                :variant="'outline-'+incomingInvoicePaymentBannerColor(selectedItems[0])"
                @click="addPayment([selectedItems[0]])"
            >
              {{ $t('addPayment') }}
            </b-button>
          </div>
        </b-alert>

        <view-incoming-invoice
            class="mt-1 invoice-preview"
            :invoice="selectedItems[0]"
        />


      </template>
    </base-template>

    <modal-payment
        :payment.sync="currentPayment"
        :title="'id' in currentPayment?$t('EditPayment'):$t('NewPayment')"
        :isOpen.sync="paymentModalShow"
        :formMaxAmount="maxPaymentAmount"

        @submitValidated="submitValidatedPaymentLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'
import { usePayments } from '../../accounting/usePayment'

import BaseTemplate from '../../../components/base/Base'
import ButtonView from '../../../components/button/View'
import ButtonPayment from '../../../components/button/Payment'
import ButtonDownload from '../../../components/button/Download'
import ButtonClose from '../../../components/button/Close'
import IncomingInvoiceCard from '../../../components/card/IncomingInvoice2'
import ModalPayment from '../../../components/prompt/Payment2'
import ViewIncomingInvoice from '../../../components/views/IncomingInvoice.vue'
import i18n from '../../../libs/i18n'
import useAPI from '../../../utils/useAPI'
import Ripple from 'vue-ripple-directive'
import store from '../../../store'
import moment from 'moment'

export default {
  directives: {
    Ripple,
  },
  components: {
    BaseTemplate,
    ButtonView,
    ButtonPayment,
    ButtonDownload,
    ButtonClose,
    IncomingInvoiceCard,
    ModalPayment,
    ViewIncomingInvoice
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('billingDate')),
        key: 'billingDate',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.t('billNumber')),
        key: 'billNumber',
        sortable: true
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: false,
        cell: {
          translate: true,
          useBadge: true,
          type: 'paymentStatus',
          // center: true
        }
      },
      {
        display: capitalize(i18n.t('supplier')),
        key: 'supplierCompany',
        sortable: true,
        cell: {
          useDisplay: true
        }
      },
      {
        display: capitalize(i18n.t('paymentDate')),
        key: 'paymentDate',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.t('totalAmount')),
        key: 'totalAmount',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
      {
        display: capitalize(i18n.t('balance')),
        key: '_balance',
        sortable: false,
        cell: {
          isCurrency: true
        }
      },

    ])
    const filters = ref([
      {
        text: capitalize(i18n.tc('SupplierReceivable')),
        key: '_filter_isSupplierReceivable',
        searchCriteria: [{
          column_name: 'bankOperations',
          comparator: 'notTotallyPaid',
        }],
        selected: true,
        disabled: true
      },
      {
        text: capitalize(i18n.tc('currentFiscalYear')),
        key: '_filter_isCurrentFiscalYear',
        searchCriteria: [{
          column_name: 'billingDate',
          comparator: 'range',
          search_text_0: '2022-07-01',
          search_text_1: '2023-06-30'
        }],
        // selected: true,
      },
      // {
      //   text: capitalize(i18n.tc('company')),
      //   key: '_filter_isCustomerCompany',
      //   searchCriteria: [{
      //     column_name: 'customerCompany',
      //     comparator: 'notNull',
      //   }],
      // },
      // {
      //   text: capitalize(i18n.tc('individual')),
      //   key: '_filter_isCustomerIndividual',
      //   searchCriteria: [{
      //     column_name: 'customerIndividual',
      //     comparator: 'notNull',
      //   }],
      // },,
      // {
      //   text: capitalize(i18n.tc('myOutgoingInvoices')),
      //   key: '_filter_myOutgoingInvoices',
      //   searchCriteria: [{
      //     column_name: 'createdBy',
      //     comparator: 'me',
      //   }],
      // },
    ])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const currentPayment = ref({})
    const paymentModalShow = ref(false)
    const maxPaymentAmount = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { incomingInvoices2, incomingInvoicesContext, autocompleteBankAccounts } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchIncomingInvoices2,
      fetchAutocompletePaymentMethods,
      fetchAutocompleteBankAccounts,
    } = useAPI()

    const { submitValidatedPayment } = usePayments()

    const hasInitialFilter = () => {
      return filters.value.some(f => f.selected)
    }

    const rowSelected = (rowSelected) => {
      selectedItems.value = rowSelected
    }

    const showDetails = (incomingInvoice) => {
      if (incomingInvoice != null) {
        store.dispatch('incomingInvoice2/getIncomingInvoice', incomingInvoice.id)
            .then(response => {
              selectedItems.value = [incomingInvoice]
              selectedItems.value[0] = response
              openViewWindow.value = true
            })

      } else {
        selectedItems.value = []
        openViewWindow.value = false
      }
    }

    const addPayment = (incomingInvoices) => {
      if (incomingInvoices.length) {
        let amount = 0
        let paymentRelations = []
        incomingInvoices.forEach(ii => {
          amount += parseFloat(ii._balance)
          paymentRelations.push({
            amount: parseFloat(ii._balance),
            incomingInvoice: ii
          })
        })

        currentPayment.value = {
          paymentMethod: { id: 1, name: i18n.t('Bank transfert') },
          date: (new Date()).toISOString().slice(0, 10),
          paymentRelations: paymentRelations,
          bankAccount: autocompleteBankAccounts.value.find(ba=>ba.id==1)
        }
        maxPaymentAmount.value = JSON.parse(JSON.stringify(amount))

        paymentModalShow.value = true
      }
    }

    const submitValidatedPaymentLocal = () => {
      submitValidatedPayment(currentPayment.value)
          .then(response => {
            currentPayment.value = {}
            selectedItems.value = []
            maxPaymentAmount.value = null
            openViewWindow.value = false
            paymentModalShow.value = false
          })
    }

    const incomingInvoicePaymentBannerColor = (incomingInvoice) => {
      if (incomingInvoice._paymentStatus == 'Waiting for payment') {
        return 'secondary'
      } else if (incomingInvoice._paymentStatus == 'Partial payment') {
        if (moment() > moment(incomingInvoice.billingDate)) {
          return 'danger'
        } else {
          return 'warning'
        }
      } else {
        return 'danger'
      }
    }

    const downloadIncomingInvoice = (incomingInvoice) => {
      console.log(incomingInvoice)
      if (incomingInvoice.receipt != null) {
        let link = document.createElement('a')
        link.href = incomingInvoice.receipt.url
        link.target = "_blank"
        link.download = incomingInvoice.receipt.name + '.' + incomingInvoice.receipt.extension
        link.click()
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (!hasInitialFilter()) {
      fetchIncomingInvoices2()
    }
    fetchAutocompletePaymentMethods()
    fetchAutocompleteBankAccounts()

    return {
      // Components
      capitalize,

      // Data
      columns,
      filters,
      openViewWindow,
      selectedItems,
      currentPayment,
      paymentModalShow,
      maxPaymentAmount,

      // Computed
      incomingInvoices2,
      incomingInvoicesContext,

      // Methods
      fetchIncomingInvoices2,
      rowSelected,
      showDetails,
      addPayment,
      submitValidatedPaymentLocal,
      incomingInvoicePaymentBannerColor,
      downloadIncomingInvoice,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>